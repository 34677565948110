import {
  cleanupEmissionConsumptionMarkup,
  getImageUrls,
  renderTextWithFootnotesReferencesV2,
} from '@oneaudi/feature-app-utils';
import { ConsumptionsAndEmissions } from '@oneaudi/vue-formatter-service';
import { Variant, ImageProps as ContentImageProps, SpacingOption } from '../EditorContentTypes';

import { WltpProps, HighlightedCarTeaserProps, ImageProps } from '../components/ComponentTypes';

const mapToImage = ({
  alternativeText,
  imageDesktop: { assetValue: desktopAssetValue },
  imageMobile: { assetValue: mobileAssetValue },
}: ContentImageProps): ImageProps => {
  return {
    alternativeText,
    imageDesktop: getImageUrls(desktopAssetValue)[0],
    imageMobile: getImageUrls(mobileAssetValue)[0],
  };
};

const mapToWltpProp = ({
  formattedConsumption,
  formattedEmission,
  formattedCo2Class,
  formattedDischargedCo2Class,
  formattedDischargedConsumption,
}: ConsumptionsAndEmissions): WltpProps | undefined => {
  if (formattedConsumption && formattedEmission) {
    const wltpProps: WltpProps = {
      formattedConsumption: renderTextWithFootnotesReferencesV2(
        cleanupEmissionConsumptionMarkup(formattedConsumption),
      ),
      formattedEmission: renderTextWithFootnotesReferencesV2(
        cleanupEmissionConsumptionMarkup(formattedEmission),
      ),
      formattedCo2Class: formattedCo2Class
        ? renderTextWithFootnotesReferencesV2(cleanupEmissionConsumptionMarkup(formattedCo2Class))
        : undefined,
      formattedDischargedCo2Class: formattedDischargedCo2Class
        ? renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(formattedDischargedCo2Class),
          )
        : undefined,
      formattedDischargedConsumption: formattedDischargedConsumption
        ? renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(formattedDischargedConsumption),
          )
        : undefined,
    };
    return wltpProps;
  }

  return undefined;
};

const mapToWltpProps = (consumptionsAndEmission: ConsumptionsAndEmissions[]) => {
  return consumptionsAndEmission
    .map(mapToWltpProp)
    .filter((wltpProp) => wltpProp !== undefined) as WltpProps[];
};

const mapAdditionalLegalText = (additionalText?: string) => {
  if (additionalText) {
    return renderTextWithFootnotesReferencesV2(additionalText);
  }
  return undefined;
};

export const mapToTeaserProps = (
  {
    headline,
    legalData: { additionalText },
    image,
    links: [primaryLink, secondaryLink],
    carIdentifiers,
  }: Variant,
  consumptionsAndEmission: ConsumptionsAndEmissions[],
  spacingOption: SpacingOption,
): HighlightedCarTeaserProps => {
  return {
    additionalLegalText: mapAdditionalLegalText(additionalText),
    wltpData: mapToWltpProps(consumptionsAndEmission),
    image: mapToImage(image),
    primaryLink,
    secondaryLink,
    carIdentifiers,
    headline: renderTextWithFootnotesReferencesV2(headline),
    spacingOption,
  };
};
