import { Content, Variant } from '../EditorContentTypes';
import { VariantWithIsPersonalization } from '../FeatureAppTypes';

const mapContent = (variant: Variant, isPersonalized = false): VariantWithIsPersonalization => {
  return {
    ...variant,
    isPersonalized,
  };
};

const findVariant = (content: Content, contentId: string) => {
  if (contentId === 'default') {
    return content.default;
  }
  return content.personalizedVariants.find((item) => item.contentId === contentId);
};

const getPersonalizedContent = (
  content: Content,
  contentId?: string,
): VariantWithIsPersonalization => {
  if (contentId) {
    const variant = findVariant(content, contentId);
    if (variant) {
      return mapContent(variant, true);
    }
  }
  return mapContent(content.default);
};

export { getPersonalizedContent };
