import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Button, ButtonGroup, Layout, LayoutItem, Text, ThemeProvider } from '@audi/audi-ui-react';
import { HighlightedCarTeaserProps, WltpProps } from './ComponentTypes';
import {
  StyledPictureContainer,
  StyledImg,
  StyledHighlightedCarTeaser,
  StyledLayoutItemText,
} from './styles';

// TODO: this is a hack, correct CSS resets should be provided by PlatformProvider
const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    box-sizing: border-box;
  }
`;
GlobalStyles.displayName = 'GlobalStyles';

export const HighlightedCarTeaser: React.FunctionComponent<HighlightedCarTeaserProps> = ({
  linkTracking,
  headline,
  wltpData,
  additionalLegalText,
  image,
  primaryLink,
  secondaryLink,
  spacingOption,
}: HighlightedCarTeaserProps) => {
  const renderWltpData = (
    {
      formattedConsumption,
      formattedEmission,
      formattedCo2Class,
      formattedDischargedCo2Class,
      formattedDischargedConsumption,
    }: WltpProps,
    index: number,
  ) => (
    <Text as="p" variant="copy2" spaceStackEnd="s" key={index}>
      {formattedConsumption && <span data-testid="consumption">{formattedConsumption}</span>}
      {formattedEmission && <span data-testid="emission">; {formattedEmission}</span>}
      {formattedCo2Class && <span data-testid="co2Class">; {formattedCo2Class}</span>}
      {formattedDischargedConsumption && (
        <span data-testid="dischargedConsumption">; {formattedDischargedConsumption}</span>
      )}
      {formattedDischargedCo2Class && (
        <span data-testid="dischargedCo2Class">; {formattedDischargedCo2Class}</span>
      )}
    </Text>
  );
  return (
    <>
      <ThemeProvider />
      <StyledHighlightedCarTeaser spacingOption={spacingOption?.spacing || 'large'}>
        <Layout data-testid="highlightedCarTeaserId" direction={{ xs: 'column', m: 'row' }}>
          <StyledPictureContainer>
            <LayoutItem basis="50%" shrink="0">
              <picture>
                <source srcSet={image.imageDesktop} media="(min-width: 768px)" />
                <StyledImg src={image.imageMobile} alt={image.alternativeText} />
              </picture>
            </LayoutItem>
          </StyledPictureContainer>
          <StyledLayoutItemText>
            <LayoutItem>
              <Text as="h2" variant="order1" spaceStackEnd="m">
                {headline}
              </Text>
              {wltpData.map(renderWltpData)}
              {additionalLegalText && (
                <Text data-testid="legalText" as="p" variant="copy2">
                  {additionalLegalText}
                </Text>
              )}
              <ButtonGroup variant="block-buttons" spaceStackStart="xxl">
                <Button
                  variant="primary"
                  stretch
                  href={primaryLink.href}
                  onClick={() =>
                    linkTracking && linkTracking(primaryLink.href, primaryLink.text, 'primary')
                  }
                >
                  {primaryLink.text}
                </Button>
                {secondaryLink && (
                  <Button
                    variant="secondary"
                    stretch
                    href={secondaryLink.href}
                    onClick={() =>
                      linkTracking &&
                      linkTracking(secondaryLink.href, secondaryLink.text, 'secondary')
                    }
                  >
                    {secondaryLink.text}
                  </Button>
                )}
              </ButtonGroup>
            </LayoutItem>
          </StyledLayoutItemText>
        </Layout>
      </StyledHighlightedCarTeaser>
    </>
  );
};
