import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { useContent, useInViewEffect, transformToTrackingText } from '@oneaudi/feature-app-utils';

import { ThemeProvider } from '@audi/audi-ui-react';
import { EditModeVariantSelector } from './components/editModeVariantSelector';
import { version } from '../../package.json';
import { AsyncFeatureAppProps, ContentState } from './FeatureAppTypes';
import { Content } from './EditorContentTypes';
import { useUseCase, useRenderMode, useLogger, Context } from './context';
import { useTrackingManager } from './context/useTrackingManager';
import { HighlightedCarTeaserSkeleton } from './components/skeleton';
import { HighlightedCarTeaser } from './components';
import { EditModeVariantSelectorProps } from './components/ComponentTypes';
import { useEditModeVariant } from './context/useEditModeVariant';
import { getEditVariant } from './utils/stateUtils';
import { mapHeadlessContent } from './utils/mapHeadlessContent';

const getImageToTrack = (desktop: string, mobile: string) => {
  return window.innerWidth > 768 ? desktop : mobile;
};

const FeatureApp: React.FC<AsyncFeatureAppProps> = ({
  ssrState,
  csrState,
  createState,
}: AsyncFeatureAppProps) => {
  const initialRender = useRef(true);
  const useCase = useUseCase();
  const initialContent = useContent<Content>();
  if (!initialContent) return;
  const content = mapHeadlessContent(initialContent);
  const logger = useLogger();
  const { vueFormatterService, localeService } = useContext(Context);

  const trackingManager = useTrackingManager();
  const [state, setState] = useState<ContentState | 'skeleton' | null>(() => {
    if (ssrState === 'skeleton') {
      // on the server
      return 'skeleton';
    }
    if (typeof ssrState === 'object') {
      // on the server
      return ssrState;
    }
    if (typeof csrState === 'object') {
      // on the client
      return csrState;
    }
    // on the client
    return null;
  });

  const [editModeVariantsProps, setEditModeVariantsProps] = useState<EditModeVariantSelectorProps>({
    contentIds: [],
  });
  const [editModeVariant] = useEditModeVariant();
  const [editMode] = useState(useRenderMode());

  useEffect(() => {
    trackingManager.ready(version);
  }, []);

  useEffect(() => {
    if (content) {
      if (editMode) {
        const contentIds = [
          'default',
          ...content.personalizedVariants.map((variant) => variant.contentId),
        ];

        setEditModeVariantsProps({ contentIds });
      }

      if (editModeVariant) {
        (async () => {
          const editModeContent = await getEditVariant(
            content,
            editModeVariant,
            vueFormatterService,
            localeService,
            logger,
          );

          try {
            setState(editModeContent);
          } catch (message) {
            logger?.error(message);
          }
        })();
      }
    }
  }, [initialContent, editModeVariant]);

  useEffect(() => {
    let mounted = true;
    // eslint-disable-next-line no-shadow
    const updateTeaserProps = (newState: ContentState) => {
      // eslint-disable-next-line no-unused-expressions
      mounted && setState(newState);
    };
    if (!state && typeof csrState === 'function') {
      // init
      csrState().then(updateTeaserProps);
    } else if (content && !initialRender.current) {
      // live update
      createState(content).then(updateTeaserProps);
    }
    initialRender.current = false;
    return () => {
      mounted = false;
    };
  }, [initialContent]);

  useInViewEffect(() => {
    if (state && typeof state === 'object') {
      trackingManager.impression(
        transformToTrackingText(state.selectedVariant.headline),
        getImageToTrack(state.teaserProps.image.imageDesktop, state.teaserProps.image.imageMobile),
        state.selectedVariant.isPersonalized,
        useCase,
        state.personalizationType,
        state.personalizationInfo?.trackParam,
        state.personalizationInfo?.iterationId,
        state.selectedVariant.carIdentifiers.modelShortcode,
        state.selectedVariant.carIdentifiers.carlineGroup,
      );
    }
  });

  const linkTracking = useCallback(
    (href: string, text: string, clickId: string): void => {
      if (state && typeof state === 'object') {
        trackingManager.click(
          transformToTrackingText(state.selectedVariant.headline),
          getImageToTrack(
            state.teaserProps.image.imageDesktop,
            state.teaserProps.image.imageMobile,
          ),
          href,
          text,
          clickId,
          state.selectedVariant.isPersonalized,
          useCase,
          state.personalizationType,
          state.personalizationInfo?.trackParam,
          state.personalizationInfo?.iterationId,
          state.teaserProps.carIdentifiers.modelShortcode,
          state.teaserProps.carIdentifiers.carlineGroup,
        );
      }
    },
    [state],
  );

  if (!state) {
    // eslint-disable-next-line consistent-return
    return null;
  }
  // eslint-disable-next-line consistent-return
  return (
    <ThemeProvider>
      {state === 'skeleton' && <HighlightedCarTeaserSkeleton />}
      {!!editMode && <EditModeVariantSelector {...editModeVariantsProps} />}
      {state !== 'skeleton' && (
        <HighlightedCarTeaser {...state.teaserProps} linkTracking={linkTracking} />
      )}
    </ThemeProvider>
  );
};

export default FeatureApp;
