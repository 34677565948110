import React, { RefObject, useRef, useContext, PropsWithChildren, useMemo } from 'react';
import { Logger } from '@feature-hub/core';

import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { AudiFootnoteReferenceServiceInterfaceV3 } from '@oneaudi/footnote-reference-service';

import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { RenderMode } from '@oneaudi/render-mode-service';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { Dependencies } from '../FeatureAppTypes';

interface State {
  readonly inViewRef: RefObject<HTMLDivElement>;
  readonly logger?: Logger;
  readonly trackingService?: TrackingServiceV2;
  readonly footnoteScope?: AudiFootnoteReferenceServiceInterfaceV3;
  readonly vueFormatterService: VueFormatterServiceInterfaceV1;
  readonly localeService: GfaLocaleServiceV1;
  readonly useCase: string;
  readonly renderMode: RenderMode;
}

interface ContextValue {
  readonly featureServices: Dependencies;
  readonly useCase: string;
}

export const Context = React.createContext<State>({} as State);

export const ContextProvider = ({
  featureServices: {
    's2:logger': logger,
    'audi-tracking-service': trackingService,
    'dbad:audi-vue-formatter-service': vueFormatterService,
    'audi-render-mode-service': renderModeService,
    'gfa:locale-service': localeService,
  },
  useCase,
  children,
}: PropsWithChildren<ContextValue>): JSX.Element => {
  const inViewRef = useRef<HTMLDivElement>(null);

  const state: State = useMemo(
    () => ({
      inViewRef,
      logger,
      trackingService,
      vueFormatterService,
      localeService,
      renderMode: renderModeService?.getRenderMode() || RenderMode.DEFAULT,
      useCase,
    }),
    [inViewRef, logger, trackingService, vueFormatterService, localeService, useCase],
  );

  return (
    <Context.Provider value={state}>
      <div ref={inViewRef}>{children}</div>
    </Context.Provider>
  );
};

export const useLogger = (): Logger | undefined => {
  return useContext(Context).logger;
};

export const useRenderMode = (): RenderMode | undefined => {
  return useContext(Context).renderMode;
};

export const useUseCase = (): string => {
  return useContext(Context).useCase;
};
