import { Asset } from '@oneaudi/falcon-tools';
import { Content, HeadlessContent, PersonalizedVariant } from '../EditorContentTypes';

export const mapHeadlessContent = (content: Content | HeadlessContent): Content => {
  if (content && '__type' in content && content.__type === 'aem-headless') {
    const normalisedContent: Content = {
      spacingOption: {
        spacing: content.fields.spacingOption_spacing,
      },
      personalizationOption: {
        type: content.fields.personalizationOption_type,
      },
      default: {
        headline: content.fields.default_headline,
        image: {
          imageDesktop: {
            assetValue: (content.fields.default_image_imageDesktop as Asset).path,
          },
          imageMobile: {
            assetValue: (content.fields.default_image_imageMobile as Asset).path,
          },
          alternativeText: content.fields.default_image_alternativeText,
        },
        legalData: {
          wltpKeys: content.fields.default_legalData_wltpKeys,
          additionalText: content.fields.default_legalData_additionalText,
        },
        links: [
          { href: content.fields.default_links_0_href, text: content.fields.default_links_0_text },
          { href: content.fields.default_links_1_href, text: content.fields.default_links_1_text },
        ],
        carIdentifiers: {
          modelShortcode: content.fields.default_carIdentifiers_modelShortcode,
          carlineGroup: content.fields.default_carIdentifiers_carlineGroup,
        },
      },
      personalizedVariants: [],
    };

    content.fields.personalizedVariants.forEach((item) => {
      const personalizedVariants: PersonalizedVariant = {
        contentId: item.fields.contentId,
        headline: item.fields.headline,
        image: {
          imageDesktop: {
            assetValue: (item.fields.image_imageDesktop as Asset).path,
          },
          imageMobile: {
            assetValue: (item.fields.image_imageMobile as Asset).path,
          },
          alternativeText: item.fields.image_alternativeText,
        },
        legalData: {
          wltpKeys: item.fields.legalData_wltpKeys,
          additionalText: item.fields.legalData_additionalText,
        },
        links: [
          { href: item.fields.links_0_href, text: item.fields.links_0_text },
          { href: item.fields.links_1_href, text: item.fields.links_1_text },
        ],
        carIdentifiers: {
          modelShortcode: item.fields.carIdentifiers_modelShortcode,
          carlineGroup: item.fields.carIdentifiers_carlineGroup,
        },
      };
      normalisedContent.personalizedVariants.push(personalizedVariants);
    });

    return normalisedContent;
  }
  return content as Content;
};
