/* eslint-disable no-unused-vars */
import React from 'react';
import { Context } from '.';
import { PersonalizationType } from '../EditorContentTypes';

export interface TrackingManager {
  ready(version: string): void;
  click(
    headline: string,
    image: string,
    targetUrl: string,
    appLabel: string,
    clickId: string,
    isPersonalized: boolean,
    useCaseName: string,
    personalizationType: PersonalizationType,
    segment?: string,
    iteration?: string,
    modelShortcode?: string,
    carlineGroup?: string,
  ): void;
  impression(
    headline: string,
    image: string,
    isPersonalized: boolean,
    useCaseName: string,
    personalizationType: PersonalizationType,
    segment?: string,
    iteration?: string,
    modelShortcode?: string,
    carlineGroup?: string,
  ): void;
}

export interface TrackingEvent {
  event: {
    eventInfo: {
      eventAction: string;
      eventName: string;
    };
    attributes: Record<string, unknown>;
  };
}

function createTrackingPayload(
  action: string,
  name: string,
  attributes: Record<string, unknown>,
): TrackingEvent {
  const { host, pathname } = document.location;
  const defaultAttributes = {
    componentName: 'highlighted-car-teaser',
    currentURL: `${host}${pathname}`,
  };

  return {
    event: {
      eventInfo: {
        eventAction: action,
        eventName: name,
      },
      attributes: {
        ...defaultAttributes,
        ...attributes,
      },
    },
  };
}

function createComponentPayload(version: string) {
  return {
    componentAdditions: {
      componentInfo: {
        implementer: 2,
        version,
      },
    },
  };
}

function getPersonalizationTypeTrackingText(personalizationType: PersonalizationType): string {
  if (personalizationType === 'none') {
    return 'static';
  }
  return personalizationType;
}

export function useTrackingManager(): TrackingManager {
  const { trackingService, logger } = React.useContext(Context);
  return React.useMemo((): TrackingManager => {
    function track(...args: Parameters<typeof createTrackingPayload>): void {
      if (trackingService) {
        const payload = createTrackingPayload(...args);
        logger?.debug('Sending tracking event: ', payload);
        trackingService.track(payload);
      }
    }

    function trackWithComponent(
      version: string,
      ...args: Parameters<typeof createTrackingPayload>
    ): void {
      if (trackingService) {
        const payload = createTrackingPayload(...args);

        logger?.debug('Sending tracking event: ', payload);

        const componentPayload = createComponentPayload(version);
        logger?.debug('Sending component info', componentPayload);

        // TODO update tracking service with componentPayload as second argument
        // when the service has been updated
        trackingService.track(payload);
      }
    }

    const relatedProducts = (
      isPersonalized: boolean,
      image: string,
      useCaseName: string,
      personalizationType: PersonalizationType,
      segment?: string,
      iteration?: string,
      carlineGroup?: string,
      modelShortcode?: string,
    ) => ({
      highlightedProduct: {
        productInfo: {
          productID: 'not available',
          productName: 'not available',
          manufacturer: 'Audi',
        },
        category: {
          primaryCategory: carlineGroup || '',
          subCategory01: modelShortcode || '',
          productType: 'highlighted car',
        },
        attributes: {
          version: isPersonalized ? 'personalized' : 'not personalized',
          ressource: image,
          useCaseName,
          personalizationType: getPersonalizationTypeTrackingText(personalizationType),
          segment,
          iteration,
        },
      },
    });

    return {
      ready(version) {
        trackWithComponent(version, 'feature_app_ready', 'feature app ready', {
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: '',
          pos: '',
        });
      },

      click(
        headline,
        image,
        targetUrl,
        appLabel,
        clickId,
        isPersonalized,
        useCaseName,
        personalizationType,
        segment,
        iteration,
        modelShortcode,
        carlineGroup,
      ) {
        const checkLinkTarget = (url: string) => {
          const tempLink = document.createElement('a');
          tempLink.href = url;

          if (tempLink.hostname === window.location.hostname) {
            return 'internal_link';
          }

          return 'exit_link';
        };

        track(checkLinkTarget(targetUrl), `highlighted car teaser - ${iteration} - click`, {
          label: appLabel,
          targetUrl,
          clickId,
          elementName: 'button',
          value: headline,
          pos: '',
          relatedProducts: relatedProducts(
            isPersonalized,
            image,
            useCaseName,
            personalizationType,
            segment,
            iteration,
            carlineGroup,
            modelShortcode,
          ),
        });
      },

      impression(
        headline,
        image,
        isPersonalized,
        useCaseName,
        personalizationType,
        segment,
        iteration,
        modelShortcode,
        carlineGroup,
      ) {
        track('impression', `highlighted car teaser - ${iteration} - impression`, {
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: headline,
          pos: '',
          relatedProducts: relatedProducts(
            isPersonalized,
            image,
            useCaseName,
            personalizationType,
            segment,
            iteration,
            carlineGroup,
            modelShortcode,
          ),
        });
      },
    };
  }, [trackingService]);
}
