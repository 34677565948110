import * as React from 'react';
import { Button, ButtonGroup, Layout, LayoutItem, Text } from '@audi/audi-ui-react';
import {
  StyledPictureContainer,
  StyledHighlightedCarTeaser,
  StyledLayoutItemText,
  SkeletonBonePicture,
  SkeletonBoneTypo,
  SkeletonBoneButton,
} from './styles';

export const HighlightedCarTeaserSkeleton: React.FunctionComponent = () => {
  return (
    <StyledHighlightedCarTeaser spacingOption="large">
      <Layout direction={{ xs: 'column', m: 'row' }}>
        <StyledPictureContainer>
          <LayoutItem basis="50%" shrink="0">
            <SkeletonBonePicture />
          </LayoutItem>
        </StyledPictureContainer>
        <StyledLayoutItemText>
          <LayoutItem grow="1" align="stretch">
            <Text as="h2" variant="order1" spaceStackEnd="m">
              <SkeletonBoneTypo boneWidth={70} />
            </Text>
            <Text as="p" variant="copy2" spaceStackEnd="s">
              <SkeletonBoneTypo />
              <SkeletonBoneTypo boneWidth={80} />
            </Text>
            <ButtonGroup variant="block-buttons" spaceStackStart="xxl">
              <SkeletonBoneButton>
                <Button variant="primary" />
              </SkeletonBoneButton>
            </ButtonGroup>
          </LayoutItem>
        </StyledLayoutItemText>
      </Layout>
    </StyledHighlightedCarTeaser>
  );
};
